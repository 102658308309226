import React from 'react';
import Helmet from 'react-helmet';

import './404.css';

const Page404 = () => (
    <>
        <Helmet>
            <body id="notfound" />
        </Helmet>
        <main>
            <section>
                <span>404</span>
                <p>
                    Page non trouvé, <a href="/">cliquez-ici pour retourner à l&apos;accueil</a>
                </p>
            </section>
        </main>
    </>
);

export default Page404;
